// @ts-nocheck
// @ts-ignore
// eslint-disable-next-line require-jsdoc
import React, { useEffect, useState } from 'react'
import { useSelector } from '@ggs/store'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { FullWidthPanel } from '@ggs/components/account/Layout'
import { Divider, Text } from '@ggs/components/paragraphs'
import { AccountPanelCard } from '@ggs/components/account/AccountPanelItems/AccountPanelCard'
import { TMIDisclosureWidget } from '@ggs/components/account/AccountPanelItems/TMIDisclosureWidget'
import Button from '@mui/material/Button'
import { useSignout } from '@ggs/components/account/Hooks/API/useSignout'
import { navigate } from 'gatsby'
import { ACCOUNT_VIEWS } from '@ggs/components/account/Enums'
import AddressView from '../../ecomm/Customer/AddressView'
import { ContactInformationReview, useSupportingLinks } from '@ggs/components/ecomm'
import useAccountViewTitleTrans from '@ggs/components/account/Hooks/useAccountViewTitleTrans'
import OrderHistoryLineItem from '@ggs/components/ecomm/Checkout/OrderHistoryLineItem'
import { clClientFactory } from '@ggs/commercelayer'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'

const { LOGIN, HISTORY, SIGNOUT, EDIT, ADDRESSES } = ACCOUNT_VIEWS

export const AccountDashboard = ({ setSelectedView, dashboardReminderProgram }) => {
  const { customer } = useSelector((state) => state.commerce)
  console.log(customer)
  const { t } = useTranslation()
  const { login: loginUrl } = useSupportingLinks()
  const viewTitleObj = useAccountViewTitleTrans()

  // Attempt 1, failed
  const { accessToken } = useCheckoutContext()
  const clAuthClient = clClientFactory.getClient()
  const clClient = clClientFactory.getClient()

  const [orders] = useState(async () => {
    const list
      = (await clClient.orders
        .list({
          sort: { placed_at: 'desc' },
          pageSize: 5,
        })
        .catch((e) => console.error(e))) || []
    return list
  })

  // Attempt 2, failed
  /*const { clClient } = useCheckoutContext()
   const [orders, setOrders] = useState([])
   const listOrders = getCustomerOrders({
   clClient,
   customerId: customer?.id,
   callbacks: {
   onSuccess: (action) => {
   console.log('GetCustomerOrdersAction::success',action)
   setOrders(action?.data)
   },
   onError: (error) => {
   console.error('GetCustomerOrdersAction::error',error)
   },
   },
   })
   useEffect(() => {
   listOrders()
   }, [])*/

  // Attempt 3, failed
  /**
   *
   * Retrieve order in latest state.
   *
   * @param {String} orderId ID of the order entity to update
   * @return {Promise<Order>} CL order
   */
  const retrieveOrder = async (orderId) => {
    let order = null
    try {
      order = await clClient.orders.retrieve(orderId, {
        include: [
          'authorizations',
          'available_payment_methods',
          'billing_address',
          'captures',
          'customer',
          'line_items',
          'payment_method',
          'payment_source',
          'shipments.available_shipping_methods',
          'shipments.shipping_method',
          'shipping_address',
          'transactions',
        ],
      })
      console.log('GetCustomerOrder::success', order)
    } catch (e) {
      console.error('GetCustomerOrder::error', e)
    }
    return order
  }
  useEffect(() => {
    const order = retrieveOrder('wAezhjogRl')
    console.log('retrieveOrder', order)
  }, [])

  const signoutFn = useSignout(() => {
    setSelectedView(LOGIN)
    navigate(loginUrl)
  })

  // const recentOrder = order ? (
  //   <AccountPanelCard
  //     title={t('account:label.inProgressOrder')}
  //     // buttonClick={() => setSelectedView(HISTORY)} formDivider={true}
  //     // buttonText={'Resume Checkout'}
  //   >
  //     <>
  //       <OrderReview order={order} variant="complete" />
  //       <OrderStatus order={order} key={`order_status_${order.id}`} />
  //       {/*<code>{JSON.stringify(order, null, '\t')}</code>*/}
  //     </>
  //   </AccountPanelCard>
  // ) : null

  console.log('AccountDashboard', {
    customer,
    orders,
    // accessToken
  })

  return (
    <FullWidthPanel>
      {/*{recentOrder}*/}
      <AccountPanelCard
        title={t('account:label.recentOrders')}
        buttonClick={() => setSelectedView(HISTORY)}
        formDivider={true}
        buttonText={t('account:button.viewOrderHistory')}
      >
        {customer?.orders?.length > 0
          ? customer.orders.map((o) => <OrderHistoryLineItem order={o} key={o.id} />)
          : t('account:notice.orderHistoryEmpty')}
      </AccountPanelCard>
      <TMIDisclosureWidget />
      <AccountPanelCard
        title={t('account:label.myDetails')}
        formDivider={true}
        buttonClick={() => setSelectedView(EDIT)}
        buttonText={t('account:button.edit')}
      >
        {/*<ContactInformationReview email={customer?.email} phone={customer?.phone} />*/}
        <ContactInformationReview {...customer?.metadata} {...customer} />
      </AccountPanelCard>
      <AccountPanelCard
        title={t('account:label.addresses')}
        formDivider={true}
        buttonClick={() => setSelectedView(ADDRESSES)}
        buttonText={t('account:button.addAddress')}
      >
        {customer?.customer_addresses?.length > 0
          ? customer.customer_addresses.map((a) => (
            <>
              <AddressView address={customer?.customer_addresses} />
              <code>{JSON.stringify(a)}</code>
            </>
          ))
          : t('account:notice.youHaveNoSavedAddresses')}
      </AccountPanelCard>
      <Divider />
      {dashboardReminderProgram && <Text textContent={dashboardReminderProgram} />}
      <AccountPanelCard>
        <Button label={viewTitleObj[SIGNOUT]} onClick={signoutFn}>
          {' '}
          {viewTitleObj[SIGNOUT]}{' '}
        </Button>
      </AccountPanelCard>
      {/*<AccountPanelCard>*/}
      {/*  {JSON.stringify(customer)}*/}
      {/*</AccountPanelCard>*/}
    </FullWidthPanel>
  )
}
