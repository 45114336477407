const ACCOUNT_VIEWS = {
  LOGIN: 'LOGIN',
  CREATE: 'CREATE',
  DASHBOARD: 'DASHBOARD',
  ORDER: 'ORDER',
  HISTORY: 'ORDERHISTORY',
  EDIT: 'EDIT',
  SIGNOUT: 'SIGNOUT',
  ADDRESSES: 'ADDRESSES',
  EDIT_ADDRESSES: 'EDIT_ADDRESSES',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
}

export default ACCOUNT_VIEWS
