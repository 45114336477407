import { InProgressCheckoutStepsLayout } from '@ggs/components/ecomm'
import { useI18n } from '@ggs/gatsby/lib'
import Box from '@mui/material/Box'
import React from 'react'

export const Checkout = ({ ecommEnabled = false, ...props }) => {
  const { t } = useI18n()
  return (
    <>
      <Box
        className="checkout__content"
        sx={{
          '> .grid-container > div': {
            px: 0,
          },
          '& .divider': {
            px: 0,
          },
        }}
      >
        {ecommEnabled ? (
          <InProgressCheckoutStepsLayout {...props} />
        ) : t('ecomm:notice.ecommDisabled')}
      </Box>
    </>
  )
}
